
import { defineComponent } from "vue"
import Footer from "@/components/Footer.vue"
import { useDataStore } from "@/store/_DataStore"
import BaseButton from "@/components/BaseButton.vue"
import calenderJson from "@/assets/data/calender.json"
import CalenderGridDivider from "@/components/calender/CalenderGridDivider.vue"

export default defineComponent({
  name: "Detail",
  components: { Footer, BaseButton, CalenderGridDivider },
  beforeRouteEnter(to, from, next) {
    const store = useDataStore()
    if (!store.currentItem.content) next({ name: "Home" })
    else next()
  },
  data() {
    const store = useDataStore()
    const today = new Date().toISOString().split("T")[0]

    return {
      store: store,
      isMobile: false,
      headerImage: store.calenderData.detailHeaderImage,
      headerImageMobile: store.calenderData.detailHeaderImageMobile,
      footer: store.calenderData.footer,
      logoImage: store.calenderData.logoImage,
      currentItem: store.currentItem,
      calenderSettings: calenderJson.calenderSettings,
      today,
    }
  },
  computed: {
    canRegister() {
      if(!calenderJson.calenderSettings.showRegisterButtonOnlyToday) return true
      else return this.currentItem.date && this.currentItem.date.toISODate() === this.today
    },
    bodyContent() {
      // Only return the first item in the body if canRegister is false
      return this.canRegister ? this.currentItem?.content?.body : [this.currentItem?.content?.body[0]];
    },
    logoImagePath() {
      return this.logoImage ? require("@/assets/images/" + this.logoImage) : ""
    },
    headerImagePath() {
      return this.headerImage
        ? this.isMobile
          ? `background-image: url('${require("@/assets/images/" + this.headerImageMobile)}')`
          : `background-image: url('${require("@/assets/images/" + this.headerImage)}')`
        : ""
    },
    itemImage() {
      if (!this.currentItem.content) return ""
      return this.currentItem.content.image ? require("@/assets/images/items/" + this.currentItem.content.image) : ""
    },
  },
  created() {
    this.isMobile = window.matchMedia("(max-width: 991.98px)").matches
    window.addEventListener("resize", this.setMobile)
  },
  mounted() {
    this.$gtm.trackView("Detail", "/detail", { date: this.currentItem.date?.toISODate() })
  },
  methods: {
    setMobile() {
      this.isMobile = window.matchMedia("(max-width: 991.98px)").matches
    },
  },
})
