import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a2f8e784"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-width" }
const _hoisted_2 = { class: "holder" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "description"
}
const _hoisted_6 = {
  key: 1,
  class: "steps"
}
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalenderGrid = _resolveComponent("CalenderGrid")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_CalenderOverlay = _resolveComponent("CalenderOverlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "page home",
      style: _normalizeStyle(_ctx.headerImagePath)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.logoImagePath,
            alt: "",
            class: "logo"
          }, null, 8, _hoisted_3),
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1),
          (_ctx.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
            : _createCommentVNode("", true),
          (_ctx.steps.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(index + 1), 1),
                    _createElementVNode("p", null, _toDisplayString(item), 1)
                  ]))
                }), 256))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_CalenderGrid, {
            settings: _ctx.calenderSettings,
            "calender-items": _ctx.calender,
            onShowOverlay: _ctx.showOverlay
          }, null, 8, ["settings", "calender-items", "onShowOverlay"])
        ])
      ]),
      _createVNode(_component_Footer, { data: _ctx.footer }, null, 8, ["data"])
    ], 4),
    (_ctx.isOverlayVisible === true)
      ? (_openBlock(), _createBlock(_component_CalenderOverlay, {
          key: 0,
          data: _ctx.overlay,
          day: _ctx.daySelected,
          onCloseOverlay: _ctx.closeOverlay
        }, null, 8, ["data", "day", "onCloseOverlay"]))
      : _createCommentVNode("", true)
  ], 64))
}