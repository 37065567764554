
import { defineComponent } from "vue"

import Footer from "@/components/Footer.vue"
import { useDataStore } from "@/store/_DataStore"

import BaseButton from "@/components/BaseButton.vue"
import InputText from "@/components/InputText.vue"
import CalenderGridDivider from "@/components/calender/CalenderGridDivider.vue"
import { email, helpers, required, sameAs } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export default defineComponent({
  name: "Login",
  components: { Footer, BaseButton, InputText, CalenderGridDivider },
  beforeRouteEnter(to, from, next) {
    const store = useDataStore()

    if (!store.currentItem.content) next({ name: "Home" })
    else next()
  },
  data() {
    const store = useDataStore()

    return {
      v$: useVuelidate(),
      isSubmitting: false,
      store: store,
      isMobile: false,
      headerImage: store.calenderData.detailHeaderImage,
      headerImageMobile: store.calenderData.detailHeaderImageMobile,
      footer: store.calenderData.footer,
      logoImage: store.calenderData.logoImage,
      currentItem: store.currentItem,
      form: {
        name: "",
        email: "",
        city: "",
        optin: false,
      },
      apiError: false,
    }
  },
  validations() {
    const alphaValidator = helpers.regex(/^[a-zA-Z]*$/)

    return {
      form: {
        name: { required, alphaValidator },
        email: { required, email },
        city: { required, alphaValidator },
        optin: { sameAs: sameAs(true) },
      },
    }
  },
  computed: {
    logoImagePath() {
      return this.logoImage ? require("@/assets/images/" + this.logoImage) : ""
    },
    headerImagePath() {
      return this.headerImage
        ? this.isMobile
          ? `background-image: url('${require("@/assets/images/" + this.headerImageMobile)}')`
          : `background-image: url('${require("@/assets/images/" + this.headerImage)}')`
        : ""
    },
  },
  created() {
    this.isMobile = window.matchMedia("(max-width: 991.98px)").matches

    window.addEventListener("resize", this.setMobile)
  },
  mounted() {
    this.$gtm.trackView("Login", "/login", { date: this.currentItem.date?.toISODate() })
  },
  methods: {
    async submit() {
      const isValid = await this.v$.$validate()
      this.isSubmitting = true

      if (!isValid) {
        this.isSubmitting = false
        return false
      }

      try {
        const res = await fetch(`${process.env.VUE_APP_API_URL}/api/register`, {
          method: "POST",
          body: JSON.stringify(this.form),
          headers: {
            "Content-Type": "application/json",
          },
        })

        const data = await res.json()
        if (data.data.success !== true) {
          this.apiError = true
          return
        }

        this.$router.push({ name: "Thankyou" })
      } catch (error) {
        console.error(error)
      }
    },
    getImageUrl(image: string) {
      return require(`../assets/images/icons/icon_${image.toLocaleLowerCase()}.svg`)
    },
    updateFormValue(value: string, field: string) {
      // Update the corresponding field in the form object
      ;(this.form as any)[field] = value
    },
    setMobile() {
      this.isMobile = window.matchMedia("(max-width: 991.98px)").matches
    },
    isValidEmail(email: string) {
      return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
  },
})
