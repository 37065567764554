
import { defineComponent } from "vue"

export default defineComponent({
  name: "InputText",
  props: {
    name: {
      type: String,
      default: "",
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 30,
    },
    error: {
      type: String,
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
  },
  methods: {
    updateInput(event: Event) {
      const inputValue = (event.target as HTMLInputElement).value;

      if (this.inputType === "text") {
        // Validate input based on inputType (allow only letters, spaces, commas, hyphens, and underscores)
        const validInput = /^[a-zA-Z\s,-_]*$/.test(inputValue);
        if (!validInput) {
          const sanitizedValue = inputValue.replace(/[^a-zA-Z\s,-_]/g, "");
          (event.target as HTMLInputElement).value = sanitizedValue;
          return;
        }
      } else if (this.inputType === "email") {
        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputValue)) {
          this.$emit("update:error", "Invalid email format");
          return;
        } else {
          this.$emit("update:error", "");
        }
      }

      this.$emit("update:modelValue", inputValue);
    },
  },
});
