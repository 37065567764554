
import { defineComponent } from "vue"
import CalenderGrid from "@/components/calender/CalenderGrid.vue"
import Footer from "../components/Footer.vue"
import CalenderOverlay from "@/components/calender/CalenderOverlay.vue"

import calenderJson from "@/assets/data/calender.json"
import { useDataStore } from "@/store/_DataStore"

export default defineComponent({
  name: "Home",
  components: { CalenderGrid, Footer, CalenderOverlay },
  data() {
    return {
      isMobile: false,
      daySelected: "",
      isOverlayVisible: false,
      settings: calenderJson.settings,
      logoImage: calenderJson.logoImage,
      headerImage: calenderJson.headerImage,
      headerImageMobile: calenderJson.headerImageMobile,
      title: calenderJson.homeTitle,
      description: calenderJson.homeDescription,
      steps: calenderJson.homeSteps,
      calenderSettings: calenderJson.calenderSettings,
      calender: calenderJson.data,
      overlay: calenderJson.overlay,
      footer: calenderJson.footer,
    }
  },
  computed: {
    logoImagePath() {
      return this.logoImage ? require("@/assets/images/" + this.logoImage) : ""
    },
    headerImagePath() {
      return this.headerImage
        ? this.isMobile
          ? `background-image: url('${require("@/assets/images/" + this.headerImageMobile)}')`
          : `background-image: url('${require("@/assets/images/" + this.headerImage)}')`
        : ""
    },
  },
  created() {
    this.isMobile = window.matchMedia("(max-width: 991.98px)").matches

    window.addEventListener("resize", this.setMobile)

    const store = useDataStore()
    store.$patch({
      calenderData: calenderJson,
    })

    const root = document.documentElement
    root.style.setProperty("--fontPrimary", this.settings.fontPrimary)
    root.style.setProperty("--fontSecondary", this.settings.fontSecondary)

    root.style.setProperty("--primary", this.settings.primary)
    root.style.setProperty("--primaryVariant", this.settings.primaryVariant)

    root.style.setProperty("--secondary", this.settings.secondary)
    root.style.setProperty("--secondaryVariant", this.settings.secondaryVariant)

    root.style.setProperty("--black", this.settings.black)
    root.style.setProperty("--white", this.settings.white)
  },
  methods: {
    setMobile() {
      this.isMobile = window.matchMedia("(max-width: 991.98px)").matches
    },
    showOverlay(ev: any) {
      const body = document.querySelector("body") as HTMLBodyElement
      body.classList.add("noscroll")
      this.daySelected = ev.toFormat("dd")
      this.isOverlayVisible = true
    },
    closeOverlay() {
      const body = document.querySelector("body") as HTMLBodyElement
      body.classList.remove("noscroll")
      this.isOverlayVisible = false
      this.daySelected = ""
    },
  },
})
