
import { defineComponent } from "vue";
import BaseButton from "./BaseButton.vue";
import ButtonSocial from "./calender/ButtonSocial.vue";

export default defineComponent({
  name: "Footer",
  components: { BaseButton, ButtonSocial },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    footerImagePath() {
      return this.data.footerImage ? `background-image: url('${require('@/assets/images/' + this.data.footerImage)}')` : '';
    }
  }
});
