import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b0c2c850"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calender" }
const _hoisted_2 = { class: "calender-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalenderGridDivider = _resolveComponent("CalenderGridDivider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedCalender, (months) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_CalenderGridDivider, {
          copy: _ctx.getItemMonth(months)
        }, null, 8, ["copy"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(months, (item, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(item.type), {
              key: index,
              data: item,
              settings: _ctx.settings,
              today: _ctx.today,
              onClick: ($event: any) => (_ctx.gridItemClick(item, item.date))
            }, null, 8, ["data", "settings", "today", "onClick"]))
          }), 128))
        ])
      ], 64))
    }), 256))
  ]))
}