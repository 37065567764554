
import { defineComponent } from "vue"

export default defineComponent({
  name: "GridItemDefault",
  props: {
    settings: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    today: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateToday() {
      return this.data.date.toFormat("dd")
    },
    isToday() {
      return this.data.date.hasSame(this.today, "day")
    },
    isPast() {
      return this.data.date.startOf("day") < this.today.startOf("day")
    },
    isOpened() {
      if (this.settings.autoOpenPastCells) {
        return this.isPast
      } else {
        return false
      }
    },
    closedImagePath() {
      return this.data.closedImage ? `background-image: url('${require("@/assets/images/items/" + this.data.closedImage)}')` : ""
    },
    todayImagePath() {
      return this.data.todayImage ? `background-image: url('${require("@/assets/images/items/" + this.data.todayImage)}')` : ""
    },
    openImagePath() {
      return this.data.openImage ? `background-image: url('${require("@/assets/images/items/" + this.data.openImage)}')` : ""
    },
    itemImage() {
      return this.isToday ? this.todayImagePath : this.isOpened ? this.openImagePath : this.closedImagePath
    },
  },
})
