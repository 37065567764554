import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bc7b98b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type", "value", "placeholder", "maxlength"]
const _hoisted_3 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["lwe-form-row", { error: _ctx.error }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      id: _ctx.name,
      class: "input-field",
      type: _ctx.inputType === 'email' ? 'email' : 'text',
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      maxlength: _ctx.maxlength,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.error), 1)
  ], 2))
}