
import { defineComponent } from "vue"
import GridItemDefault from "./GridItemDefault.vue"
import GridItemSquare from "./GridItemSquare.vue"
import GridItemLarge from "./GridItemLarge.vue"
import CalenderGridDivider from "./CalenderGridDivider.vue"

import { DateTime } from "luxon"
import { useDataStore } from "@/store/_DataStore"

export default defineComponent({
  name: "CalenderGrid",
  components: {
    GridItemDefault,
    GridItemSquare,
    GridItemLarge,
    CalenderGridDivider,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    calenderItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedCalender(): [] {
      const calenderDays = this.calenderItems.filter((item: any) => item.type === "GridItemDefault")
      const monthArray = calenderDays.map((day: any) => day.date.month)
      const uniqueMonths = [...new Set(monthArray)]

      let formattedMonths = [] as any

      //TODO: Refactor already added cells check
      uniqueMonths.forEach((item) => {
        const months = this.calenderItems.filter((day: any) => (!day.date || day.date.month == item) && !day.added)
        months.forEach((month: any) => {
          month.added = true
        })
        formattedMonths.push(months)
      })

      formattedMonths.forEach((month: any) => {
        month.forEach((day: any) => {
          delete day.added
        })
      })
      return formattedMonths
    },
    today(): DateTime {
      return DateTime.now()
    },
    allowPastCells() {
      return this.settings.allowPastCells
    },
  },
  created() {
    const startDate = DateTime.fromFormat(this.settings.startDate, "dd-MM-yyyy", { locale: "nl" })
    let counter = 0

    this.calenderItems.map((item: any, index: number) => {
      if (item.type !== "GridItemDefault") return

      item.date = startDate.plus({ days: counter })
      counter++
    })
  },
  methods: {
    gridItemClick(item: any, today: DateTime) {
      if (item.type !== "GridItemDefault") return false

      const isToday = today.hasSame(this.today, "day")
      const isPast = today < this.today

      if (this.allowPastCells) {
        if (!isPast) {
          this.$emit("showOverlay", today)
          return false
        }
      } else {
        if (!isToday) {
          this.$emit("showOverlay", today)
          return false
        }
      }

      const store = useDataStore()
      store.$patch({
        currentItem: item,
      })

      this.$router.push({ name: "Detail" })
    },
    getItemMonth(months: any) {
      const itemsWithDate = months.filter((item: any) => item.date)
      return itemsWithDate[0].date.monthLong
    },
  },
})
