
import { defineComponent } from "vue"

import Footer from "@/components/Footer.vue"
import { useDataStore } from "@/store/_DataStore"

import BaseButton from "@/components/BaseButton.vue"

export default defineComponent({
  name: "Thankyou",
  components: { Footer, BaseButton },
  beforeRouteEnter(to, from, next) {
    const store = useDataStore()

    if (!store.currentItem.content) next({ name: "Home" })
    else next()
  },
  data() {
    const store = useDataStore()

    return {
      store: store,
      isMobile: false,
      headerImage: store.calenderData.detailHeaderImage,
      headerImageMobile: store.calenderData.detailHeaderImageMobile,
      footer: store.calenderData.footer,
      logoImage: store.calenderData.logoImage,
      currentItem: store.currentItem,
    }
  },
  computed: {
    logoImagePath() {
      return this.logoImage ? require("@/assets/images/" + this.logoImage) : ""
    },
    headerImagePath() {
      return this.headerImage
        ? this.isMobile
          ? `background-image: url('${require("@/assets/images/" + this.headerImageMobile)}')`
          : `background-image: url('${require("@/assets/images/" + this.headerImage)}')`
        : ""
    },
  },
  created() {
    this.isMobile = window.matchMedia("(max-width: 991.98px)").matches

    window.addEventListener("resize", this.setMobile)
  },
  mounted() {
    // this.$gtm.trackView("Thankyou", "/thankyou", { date: this.currentItem.date?.toISODate() })
  },
  methods: {
    setMobile() {
      this.isMobile = window.matchMedia("(max-width: 991.98px)").matches
    },
  },
})
