import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9cbf1efe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-width" }
const _hoisted_2 = { class: "holder" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "button-holder"
}
const _hoisted_5 = {
  key: 1,
  class: "share-holder"
}
const _hoisted_6 = {
  key: 2,
  class: "footer-menu"
}
const _hoisted_7 = { class: "label" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ButtonSocial = _resolveComponent("ButtonSocial")!

  return (_openBlock(), _createElementBlock("div", {
    class: "footer",
    style: _normalizeStyle(_ctx.footerImagePath)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.title), 1),
        (_ctx.data.button)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_BaseButton, {
                class: "primary",
                href: _ctx.data.button.href
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.data.button.cta), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.data.socials)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.socials, (item) => {
                return (_openBlock(), _createBlock(_component_ButtonSocial, {
                  type: item.type,
                  href: item.href
                }, null, 8, ["type", "href"]))
              }), 256))
            ]))
          : _createCommentVNode("", true),
        (_ctx.data.menu)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.data.copyright), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.menu, (item) => {
                return (_openBlock(), _createElementBlock("a", {
                  href: item.href,
                  target: "_blank",
                  class: "label"
                }, _toDisplayString(item.text), 9, _hoisted_8))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 4))
}