
import { defineComponent } from "vue"
import BaseButton from "../BaseButton.vue"

export default defineComponent({
  name: "GridItemLarge",
  components: { BaseButton },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    itemImagePath() {
      return this.data.backgroundImage
        ? `background-image: url('${require("@/assets/images/" + this.data.backgroundImage)}'), radial-gradient(circle, var(--primary) 0%, var(--primaryVariant) 100%)`
        : ""
    },
  },
})
