
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    href: String,
    target: String,
    iconLeft: String,
    iconRight: String,
  },
  data() {
    return {
      anchorUrl: "",
      anchorTarget: "",
    };
  },
  mounted() {
    if (this.href) {
      //Set terms target & url
      this.anchorUrl = this.href;
      this.anchorTarget = this.target ? this.target : "_blank";
    }
  },
  computed: {
    isAnchor(): string {
      return this.href ? "a" : "div";
    },
  },
});
