import { ICalenderData, ICalenderItemData } from '@/interfaces/_ICore'
import { defineStore } from 'pinia'

export const useDataStore = defineStore('data', {
  state: () => {
    return {
      calenderData: {} as ICalenderData,
      currentItem: {} as ICalenderItemData
    }
  },
  actions: {

  },
  getters: {

  }  
})