import { PageName } from "@/utils/_Constants"
import { createRouter, createWebHistory, RouteRecordRaw, RouterScrollBehavior } from "vue-router"
import Home from "../views/Home.vue"
import Detail from "../views/Detail.vue"
import Login from "../views/Login.vue"
import Thankyou from "../views/Thankyou.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: PageName.HOME,
    component: Home,
  },
  {
    path: "/detail",
    name: PageName.DETAIL,
    component: Detail,
  },
  {
    path: "/login",
    name: PageName.LOGIN,
    component: Login,
  },
  {
    path: "/thankyou",
    name: PageName.THANKYOU,
    component: Thankyou,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
